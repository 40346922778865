<template>
  <div class="home">
    <div class="home-top d-flex flex-row align-center justify-between">
      <div class="left">
        <h1>Best bouquets</h1>
        <p>
          Roses Lovelace+ came from Ecuador. Ecuadorian roses are the best in
          the world. Roses Lovelace+ are very softpink with a touch of lavender.
        </p>
        <Button :message="'Go shop'"></Button>
      </div>
      <div class="right">
        <img
          src="@/assets/img/Home/top-flower.png"
          alt="flower"
          width="575"
          height="444"
        />
      </div>
    </div>

    <div class="home-favorite">
      <h3>Favorite of our clients</h3>
<!--      <div class="wrapper d-flex flex-row align-center justify-between">-->
<!--        <div class="left">-->
<!--          <img-->
<!--            src="@/assets/img/Home/favorite-left.png"-->
<!--            alt="favorite-left.png"-->
<!--            width="738"-->
<!--            height="560"-->
<!--          />-->
<!--          <div class="wrapper-desc">-->
<!--            <h5>Lavander Rose</h5>-->
<!--            <p>€ 2.43 per rose</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <img-->
<!--            src="@/assets/img/Home/favorite-right.png"-->
<!--            alt="favorite-right.png"-->
<!--            width="378"-->
<!--            height="280"-->
<!--          />-->
<!--          <div class="wrapper-desc">-->
<!--            <h5>Mon chéri</h5>-->
<!--            <p>from € 56.43</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="main-container">
        <div class="card-products big-card">
          <img src="@/assets/img/Home/favorite-left.png"/>
          <div class="wrapper-desc">
            <h5 class="product-name"></h5>
            <h5 class="product-price">€  per rose</h5>
          </div>
        </div>
        <div class="card-products small-card">
          <img src="@/assets/img/Home/favorite-right.png"/>
          <div class="wrapper-desc">
            <h5 class="product-name"></h5>
            <h5 class="product-price">€ 2.40 per rose</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="home-categories">
      <h2>Categories</h2>
      <div class="slider">
        <swiper class="swiper" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(slide, id) in sliderArr" :key="id">
            <div class="left">
              <img
                :src="require(`@/assets/img/Slider/${slide.img}`)"
                :alt="slide.img"
              />
            </div>
            <div class="right">
              <h2>{{ slide.title }}</h2>
              <p>
                {{ slide.desc }}
              </p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </div>

    <div
      class="home-extra-benefits d-flex flex-row align-center justify-between"
    >
      <div class="left">
        <h3>Extra benefits in our app</h3>
        <p>
          Save points for extra discounts on our bouquets! Even better: you can
          also pass the discount vouchers on to friends, family or colleagues.
        </p>
      </div>
      <div class="right">
        <img
          src="@/assets/img/Home/benefits.png"
          alt="benefits"
          width="886"
          height="700"
        />
      </div>
    </div>
    <div
      class="home-video-message d-flex flex-row align-center justify-between"
    >
      <div class="left">
        <img src="@/assets/img/Home/video-msg.png" alt="video-msg" />
      </div>
      <div class="right">
        <h3>Video message</h3>
        <p>
          You can record video or select one from recents* and send it with
          bouquet. Video can be max 60s. This video will be a part of your
          order.
          <span
            >The video will be compiled and recipient will get QR code via
            e-mail. When recipient will scan the code he will see your message!
          </span>
        </p>
        <div class="img-container">
          <img
            src="@/assets/img/Social/appstore.png"
            alt="appstore"
            width="180"
            height="52"
          />
          <img
            src="@/assets/img/Social/googleplay.png"
            alt="googleplay"
            width="180"
            height="52"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
} from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Mousewheel, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
import "swiper/swiper-bundle.min.css";

import Button from "@/components/Button/CustomButton.vue";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    Button,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods:{
    test(){
      console.log(1)
    }
  },
  mounted() {
    this.swiper.slideTo(1, 1000, false);
    document.querySelector('.custom-button').addEventListener('click',()=>{
      this.$router.push('/shop')
    });
  },
  data() {
    return {
      sliderArr: [
        {
          img: "img1.png",
          title: "Congratulations",
          desc: "Congratulates someone with a flower! Here you will find the flowers for birthdays, anniversaries and other special performances.",
        },
        {
          img: "img1.png",
          title: "Congratulations 2",
          desc: "Congratulates someone with a flower! Here you will find the flowers for birthdays, anniversaries and other special performances.",
        },
        {
          img: "img1.png",
          title: "Congratulations 3",
          desc: "Congratulates someone with a flower! Here you will find the flowers for birthdays, anniversaries and other special performances.",
        },
        {
          img: "img1.png",
          title: "Congratulations 4",
          desc: "Congratulates someone with a flower! Here you will find the flowers for birthdays, anniversaries and other special performances.",
        },
        {
          img: "img1.png",
          title: "Congratulations 5",
          desc: "Congratulates someone with a flower! Here you will find the flowers for birthdays, anniversaries and other special performances.",
        },
        {
          img: "img1.png",
          title: "Congratulations 6",
          desc: "Congratulates someone with a flower! Here you will find the flowers for birthdays, anniversaries and other special performances.",
        },
      ],
      swiperOption: {
        spaceBetween: 100,
        grabCursor: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
          delay: 3500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        breakpoints: {
          320: {
            slidesPerView: "auto",
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      testig:'dsad'
    };
  },
};
</script>
